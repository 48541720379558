import { ReactElement, useEffect, useMemo, useState } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { Copyright } from "../../components";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { Card, CardHeader, IconButton } from "@mui/material";
import { FaEye } from "react-icons/fa";
import {
  GetAllPlayerBans,
  PlayerBanReport,
} from "../../endpoints/useGetAllPlayerBans";

const ROOT = makeRootClassName("PlayerBansPage");
const el = makeElementClassNameFactory(ROOT);

export function PlayerBansPage(): ReactElement {
  const [data, setData] = useState<PlayerBanReport[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function FetchData() {
      const response = await GetAllPlayerBans();
      if (response && response.data) {
        setData(response.data.players);
      }
      setLoading(false);
    }
    FetchData();
  }, []);

  const columns = useMemo<MRT_ColumnDef<PlayerBanReport>[]>(
    () => [
      {
        id: "steamId",
        header: "Steam ID",
        accessorFn: (row) => row.steamId,
      },
      {
        id: "steamId",
        header: "Steam ID",
        accessorFn: (row) => row.daysSinceLastBan,
      },
      {
        id: "banStatus",
        header: "Ban Status",
        accessorFn: (row) => {
          let status = "";
          if (row.communityBanned) status += "Community ";
          if (row.vacBanned) status += "VAC ";
          if (row.economyBan) status += "Economy ";
          return status;
        },
      },
      {
        id: "numberOfGameBans",
        header: "# Game Bans",
        accessorFn: (row) => row.numberOfGameBans,
      },
      {
        id: "numberOfVACBans",
        header: "# VAC Bans",
        accessorFn: (row) => row.numberOfVACBans,
      },
      {
        id: "daysSinceLastBan",
        header: "Days since Last Ban",
        accessorFn: (row) => row.daysSinceLastBan,
      },
    ],
    [data],
  );

  return (
    <div className={ROOT}>
      <Card>
        <CardHeader
          title="Player Bans"
          subheader="All players banned from our services."
        />
      </Card>
      <MaterialReactTable
        columns={columns}
        data={data}
        positionGlobalFilter="left"
        state={{ isLoading: loading, showGlobalFilter: true }}
        muiSearchTextFieldProps={{
          placeholder: "Search all bans",
          sx: { minWidth: "300px", padding: "8px" },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            header: "",
          },
        }}
        enableRowActions
        renderRowActions={({ row, table }) => (
          <IconButton onClick={() => console.log(row)}>
            <FaEye size={20} />
          </IconButton>
        )}
        initialState={{
          density: "compact",
        }}
        enableDensityToggle={false}
        enableColumnFilters={false}
        enableHiding={false}
      />
      <Copyright absolute />
    </div>
  );
}
