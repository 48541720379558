import { ApiGet } from "./api";

export type CheatingReportResult = {
  reportid: string;
  steamid: string;
  steamidreporter: string;
  appdata: string;
  playerreport: string;
  heuristic: string;
  detection: string;
  timereport: string;
  gamemode: string;
  matchid: string;
  cheating_type: string;
};

export type GetCheatingReportsOutput = {
  response: {
    results: CheatingReportResult[];
  };
};

export type GetCheatingReportsInput = {
  sinceWhen?: number;
  includeReports?: boolean;
  includeBans?: boolean;
};

export const GetAllCheatingReports = async (
  input?: GetCheatingReportsInput,
) => {
  const response = await ApiGet(
    "/GetCheatingReports/GetReports",
    input && {
      sinceWhen: input?.sinceWhen,
      includeReports: input?.includeReports,
      includeBans: input?.includeBans,
    },
  );
  let data;
  if (response && response.data) {
    data = response.data as GetCheatingReportsOutput;
  }

  return { data };
};
