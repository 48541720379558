import { ReactElement } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { IconButton } from "@mui/material";
import { useSiteTheme } from "../../hooks/useSiteTheme";
import { BsMoonFill, BsSunFill } from "react-icons/bs";

const ROOT = makeRootClassName("ThemeChanger");
const el = makeElementClassNameFactory(ROOT);

type ThemeChangerProps = {
  toggleTheme: () => void;
};

export function ThemeChanger(props: ThemeChangerProps): ReactElement {
  const { theme } = useSiteTheme();

  return (
    <div className={ROOT}>
      <IconButton onClick={props.toggleTheme}>
        {theme.palette.mode === "light" ? (
          <BsMoonFill size={30} />
        ) : (
          <BsSunFill size={30} />
        )}
      </IconButton>
    </div>
  );
}
