import { ApiGet } from "./api";
import { GetPlayerOutput } from "./useGetPlayer";

export type GetAllPlayersOutput = {
  players: GetPlayerOutput[];
};

export type GetAllPlayersInput = {
  page: number;
  itemsPerPage: number;
};

export const useGetAllPlayers = async (input?: GetAllPlayersInput) => {
  const response = await ApiGet(
    "/Player/GetAll",
    input && {
      page: input.page,
      itemsPerPage: input.itemsPerPage,
    },
  );
  let data;
  if (response && response.data) {
    data = response.data;
  }

  const playerOutput = data as GetPlayerOutput[];
  data = {
    players: playerOutput,
  } as GetAllPlayersOutput;

  return { data };
};
