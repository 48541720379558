import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { Authenticate } from "../../endpoints/useAuthenticate";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";

const ROOT = makeRootClassName("GoogleOneTap");
const el = makeElementClassNameFactory(ROOT);

export const GoogleOneTap = () => {
  const OnSuccess = async (response: CredentialResponse) => {
    const { data } = await Authenticate({
      credential: response.credential,
    });

    if (data.token) {
      let jwt = data.token;
      localStorage.setItem("jwt", jwt);
      window.location.reload();
    }
  };

  const onError = () => {
    console.log("Error getting GoogleOneTap response!");
  };

  return (
    <div className={ROOT}>
      <GoogleLogin
        logo_alignment={"center"}
        useOneTap
        onSuccess={OnSuccess}
        onError={onError}
      />
    </div>
  );
};
