import { ApiGet } from "./api";

export type PlayerBanReport = {
  steamId: string;
  communityBanned: boolean;
  vacBanned: boolean;
  numberOfVACBans: number;
  daysSinceLastBan: number;
  numberOfGameBans: number;
  economyBan: string;
};

export type GetAllPlayerBansOutput = {
  players: PlayerBanReport[];
};

export type GetAllPlayerBansInput = {
  steamIds: string[];
};

export const GetAllPlayerBans = async (input?: GetAllPlayerBansInput) => {
  let endpoint;
  if (input == null) endpoint = "Server/GameBan/GetAllBannedPlayers";
  else endpoint = "Server/GameBan/GetAllPlayerBans";
  const response = await ApiGet(
    endpoint,
    input && {
      steamId: input.steamIds.join(","),
    },
  );
  let data;
  if (response && response.data) {
    data = response.data as GetAllPlayerBansOutput;
  }
  return { data };
};
