import { ReactElement, useState } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { StyleProps } from "../../types/props";
import clsx from "clsx";

const ROOT = makeRootClassName("HiddenText");
const el = makeElementClassNameFactory(ROOT);

type HiddenTextProps = StyleProps & {
  text: string;
};

export function HiddenText(props: HiddenTextProps): ReactElement {
  const [isHidden, setHidden] = useState(true);

  const handleClickText = () => {
    setHidden((hidden) => !hidden);
  };

  return (
    <div className={ROOT}>
      <button
        className={clsx(el`button`, {
          hidden: isHidden,
        })}
        onClick={handleClickText}
      >
        {props.text}
      </button>
    </div>
  );
}
