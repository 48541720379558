import { ReactElement } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { Copyright } from "../../components";
import { PiSmileySadFill } from "react-icons/pi";
import { useSiteTheme } from "../../hooks/useSiteTheme";

const ROOT = makeRootClassName("TokenExpiredPage");
const el = makeElementClassNameFactory(ROOT);

export function TokenExpiredPage(): ReactElement {
  const { theme } = useSiteTheme();

  return (
    <div>
      <div className={ROOT}>
        <span className={el`iconLabel`}>
          <PiSmileySadFill fill={theme.palette.primary.main} size={50} />
          <h1>Uh oh, your session has expired!</h1>
        </span>
      </div>
      <Copyright absolute />
    </div>
  );
}
