import { ReactElement } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { StyleProps } from "../../types/props";
import { ThemeHelper } from "../ThemeHelper";
import { Sidebar, UserAvatar } from "../../components";
import { Container } from "@mui/material";
import { useAdmin } from "../../hooks/useAdmin";

const ROOT = makeRootClassName("Layout");
const el = makeElementClassNameFactory(ROOT);

type LayoutProps = StyleProps & {
  children: ReactElement;
};

export function Layout(props: LayoutProps): ReactElement {
  const { isAdmin } = useAdmin();

  return (
    <div className={ROOT}>
      <ThemeHelper>
        <>
          {isAdmin && <UserAvatar />}
          <div className={el`container`}>
            {isAdmin && <Sidebar />}
            <Container>{props.children}</Container>
          </div>
        </>
      </ThemeHelper>
    </div>
  );
}
