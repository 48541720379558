enum Page {
  Dashboard,

  Player,
  Players,
  PlayerReports,
  PlayerBans,

  RCON,
  Server,
  Servers,
  ServerReports,
  ServerBans,
}

export default Page;
