import { useEffect, useMemo, useState } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { usePage } from "../../hooks/usePage";
import { GetServerOutput } from "../../endpoints/useGetServers";
import { Button, Card, CardHeader } from "@mui/material";
import Page from "../../types/page";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import {
  FaBox,
  FaCalendar,
  FaDoorOpen,
  FaIdBadge,
  FaInfoCircle,
  FaServer,
  FaSteam,
  FaUsers,
} from "react-icons/fa";
import { HiddenText, RCONConsole } from "../../components";
import { KickServerPlayer } from "../../endpoints/useKickServerPlayer";
import {RCONPlayerData} from "../../types/rcon";
import {HiCommandLine} from "react-icons/hi2";

const ROOT = makeRootClassName("ServerPage");
const el = makeElementClassNameFactory(ROOT);

export function ServerPage() {
  const [data, setData] = useState<GetServerOutput>();
  const [playerData, setPlayerData] = useState<RCONPlayerData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { pageProps, setPage } = usePage();

  const server = pageProps.server as GetServerOutput;

  useEffect(() => {
    if (!pageProps?.server) {
      setPage(Page.Servers);
      return;
    }

    setData(server);
  }, []);

  const columns = useMemo<MRT_ColumnDef<RCONPlayerData>[]>(
    () => [
      {
        id: "name",
        header: "Name",
        accessorFn: (row) => row.Name,
      },
      {
        id: "steamId",
        header: "SteamId",
        accessorFn: (row) => row.SteamId,
      },
    ],
    [],
  );

  const handleKickPlayer = (index: number) => {
    if (
      !window.confirm(
        "Are you sure you want to kick " + playerData[index].Name + "?",
      )
    )
      return;
    const steamId = Number(playerData[index].SteamId);
    KickServerPlayer({
      serverIp: server.realServerIP,
      serverPort: server.rconPort,
      steamId,
    });
  };

  const playersTab = (): JSX.Element => {
    return (
      <MaterialReactTable
        columns={columns}
        data={playerData}
        positionGlobalFilter="left"
        state={{ isLoading: loading, showGlobalFilter: true }}
        muiSearchTextFieldProps={{
          placeholder: "Search all players",
          sx: { minWidth: "300px", padding: "8fpx" },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            header: "",
          },
        }}
        renderRowActions={({ row }) => (
          <>
            {playerData[row.index].IsAdmin ?? <FaIdBadge />}
            <Button
              startIcon={<FaDoorOpen />}
              onClick={() => handleKickPlayer(row.index)}
            />
          </>
        )}
        enableRowActions
        initialState={{
          density: "compact",
        }}
        enableDensityToggle={false}
        enableColumnFilters={false}
        enableHiding={false}
      />
    );
  };

  const informationTab = (): JSX.Element => {
    return (
      <Card className={el`information-table`}>
        <div className={el`subinformation-table`}>
          <div className={el`infobox`}>
            <FaBox size={20} className={el`highlight`} />
            <p>{data?.name}</p>
          </div>
          <div className={el`infobox`}>
            <FaServer className={el`highlight`} size={20} />
            <p>{data?.addr}</p>
          </div>
          <div className={el`infobox`}>
            <HiCommandLine size={20} className={el`highlight`}/>
            <p>{data?.realServerIP}:{data?.rconPort}</p>
          </div>
        </div>

        <div className={el`subinformation-table`}>
          <div className={el`infobox`}>
            <FaInfoCircle size={20} className={el`highlight`}/>
            <p>
              {data?.os == "w" ? "Windows" : data?.os == "l" ? "Linux" : "Mac"}{" "}
              | {data?.serverSteamID} | {data?.version} | {data?.secure ? "Secure" : "Not-Secure"}
            </p>
          </div>
          <div className={el`infobox`}>
            <FaUsers size={20} className={el`highlight`}/>
            <p>
              {data?.players}/{data?.maxPlayers}
            </p>
          </div>
          <div className={el`infobox`}>
            <FaCalendar size={20} className={el`highlight`}/>
            <p>{data?.firstTimeSeen}</p>
          </div>
        </div>
      </Card>
    );
  };

  const rconConsole = (): JSX.Element => {
    return (
        <>
          <RCONConsole
            connectionAddr={server.realServerIP}
            connectionPort={server.rconPort}
            setPlayerData={(rconPlayerData: RCONPlayerData[]) => {
              setPlayerData(rconPlayerData);
              setLoading(false);
            }}
          />
      </>
    );
  };

  return (
    <div className={ROOT}>
      <Button
        variant={"outlined"}
        onClick={() => {
          setPage(Page.Servers);
        }}
      >
        Back to Servers
      </Button>
      <div className={el`header-cards`}>
        <Card>
          <CardHeader title={data?.name} avatar={<FaServer />} />
        </Card>
        <div className={el`divider`}></div>
        {informationTab()}
        <div className={el`divider`}></div>
        {playersTab()}
        <div className={el`divider`}></div>
        {rconConsole()}
        <div className={el`divider`}></div>
      </div>
    </div>
  );
}
