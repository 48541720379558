import { ReactElement, useEffect, useMemo, useState } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  MRT_Row,
} from "material-react-table";
import { Card, CardHeader, IconButton } from "@mui/material";
import { Copyright } from "../../components";
import { FaSearch } from "react-icons/fa";
import { usePage } from "../../hooks/usePage";
import Page from "../../types/page";
import { GetServerOutput, GetServers } from "../../endpoints/useGetServers";

const ROOT = makeRootClassName("ServersPage");
const el = makeElementClassNameFactory(ROOT);

export function ServersPage(): ReactElement {
  const [data, setData] = useState<GetServerOutput[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const { setPage, setPageProps } = usePage();

  useEffect(() => {
    async function FetchData() {
      const response = await GetServers();
      if (response) {
        setData([
          {
            realServerIP: "73.108.222.100",
            serverSteamID: "0",
            name: "Isaac's Development Server",
            firstTimeSeen: "12312312",
            official: true,
            adminsSteamID: "1223123",
            addr: "73.108.222.100",
            gamePort: 27015,
            rconPort: 27014,
            appId: 1,
            gameDir: "gameDir",
            version: "v1",
            product: "product",
            region: 0,
            players: 0,
            maxPlayers: 4,
            bots: 0,
            map: "map",
            secure: true,
            dedicated: true,
            os: "os",
          },
          ...response.data.matchedServers,
        ]);
      }
      setLoading(false);
    }
    FetchData();
  }, []);

  const firstSeenSortingFunc = useMemo(() => {
    return (
      rowA: MRT_Row<GetServerOutput>,
      rowB: MRT_Row<GetServerOutput>,
      columnId: string,
    ) => {
      const timeCreatedA = rowA._valuesCache.firstTimeSeen;
      const timeCreatedB = rowB._valuesCache.firstTimeSeen;

      if (timeCreatedA === timeCreatedB) {
        return 0;
      } else {
        return timeCreatedA > timeCreatedB ? 1 : -1;
      }
    };
  }, []);

  const columns = useMemo<MRT_ColumnDef<GetServerOutput>[]>(
    () => [
      {
        id: "name",
        header: "Name",
        accessorFn: (row) => row.name,
      },
      {
        id: "real_ip",
        header: "IP Address",
        accessorFn: (row) => row.realServerIP,
      },
      {
        id: "steam_id",
        header: "SteamID",
        accessorFn: (row) => row.serverSteamID,
      },
      {
        id: "firstSeen",
        header: "First Seen",
        accessorFn: (row) => `${row.firstTimeSeen}`,
        sortType: firstSeenSortingFunc,
      },
      {
        id: "playerCount",
        header: "Players",
        accessorFn: (row) => `${row.players}/${row.maxPlayers}`,
        size: 1,
      },
      {
        id: "version",
        header: "Version",
        accessorFn: (row) => `${row.version}`,
        size: 1,
      },
    ],
    [],
  );

  const onInspect = (server: GetServerOutput) => {
    setPageProps({ server: server });
    setPage(Page.Server);
  };

  return (
    <div className={ROOT}>
      <Card>
        <CardHeader
          title="Servers"
          subheader="All servers authenticated through our services."
        />
      </Card>
      <MaterialReactTable
        columns={columns}
        data={data ?? []}
        positionGlobalFilter="left"
        state={{ isLoading: loading, showGlobalFilter: true }}
        muiSearchTextFieldProps={{
          placeholder: "Search all servers",
          sx: { minWidth: "300px", padding: "8px" },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            header: "",
          },
        }}
        enableRowActions
        renderRowActions={({ row }) => (
          <IconButton
            onClick={() => {
              if (data !== undefined) {
                onInspect(data[row.index]);
              }
            }}
          >
            <FaSearch size={15} />
          </IconButton>
        )}
        initialState={{
          density: "compact",
        }}
        enableDensityToggle={false}
        enableColumnFilters={false}
        enableHiding={false}
      />
      <Copyright absolute />
    </div>
  );
}
