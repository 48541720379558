import { ReactElement } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { StyleProps } from "../../types/props";
import clsx from "clsx";

const ROOT = makeRootClassName("Copyright");
const el = makeElementClassNameFactory(ROOT);

type CopyrightProps = StyleProps & {
  absolute?: boolean;
};

export function Copyright(props: CopyrightProps): ReactElement {
  return (
    <div
      className={clsx(ROOT, {
        "is-absolute": props.absolute,
      })}
    >
      <p>Copyright © 2023 Anklebreaker Studio | v0.0.2</p>
    </div>
  );
}
