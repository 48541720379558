import { ReactElement, ReactNode, useState } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { StyleProps } from "../../types/props";
import { Modal } from "antd";
import { Button } from "@mui/material";

const ROOT = makeRootClassName("SimpleModal");
const el = makeElementClassNameFactory(ROOT);

type SimpleModalProps = StyleProps & {
  children: ReactElement;
  label: string;
  icon?: ReactNode;
  onOkay: () => {};
  isLoading?: boolean;
};

export function SimpleModal(props: SimpleModalProps): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    props.onOkay();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={ROOT}>
      <Button variant={"outlined"} onClick={showModal}>
        {props.label}
      </Button>
      <Modal
        title={props.label}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={props.isLoading}
        footer={[
          <div className={el`footer`}>
            <Button variant={"contained"} onClick={handleOk}>
              Okay
            </Button>
            <Button variant={"outlined"} onClick={handleCancel}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        {props.children}
      </Modal>
    </div>
  );
}
