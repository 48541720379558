import { ApiPut } from "./api";
import { GetPlayerOutput } from "./useGetPlayer";

export type SetTrustInput = {
  steamId: string;
  trustFactor: number;
};

export const SetPlayerTrustFactor = async (input?: SetTrustInput) => {
  const response = await ApiPut("Player/UpdateTrustFactor", input);

  let data;
  if (response && response.data && response.status == 200) {
    data = response.data as GetPlayerOutput;
  }

  return { data };
};
