import React, { ReactElement } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import Page from "../../types/page";
import { FileNotFoundPage, NoAccessPage, TokenExpiredPage } from "../../pages";
import { useAdmin } from "../../hooks/useAdmin";
import { usePage } from "../../hooks/usePage";
import { SitePages } from "../../pages";
import { IsTokenExpiring, RefreshToken } from "../../endpoints/api";
import { Container } from "@mui/material";

const ROOT = makeRootClassName("Layout");
const el = makeElementClassNameFactory(ROOT);

export function SPARouter(): ReactElement {
  const { isAdmin } = useAdmin();
  const { page } = usePage();

  const authRoute = (element: ReactElement) => {
    if (!isAdmin) return <NoAccessPage />;
    let token = localStorage.getItem("jwt");
    if (!token) return <NoAccessPage />;
    if (IsTokenExpiring(token)) {
      RefreshToken(token);
      return <TokenExpiredPage />;
    }
    return element;
  };

  const getPageElement = (page: Page): JSX.Element => {
    const pageData = SitePages.find(
      (p) => (p.page as number) == (page as number),
    );
    if (!pageData) return <FileNotFoundPage />;
    return pageData.component;
  };

  const renderPage = (): JSX.Element => {
    return authRoute(getPageElement(page));
  };

  return <div className={ROOT}>{renderPage()}</div>;
}
