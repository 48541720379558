import { ReactElement, useEffect, useMemo, useState } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  MRT_Row,
} from "material-react-table";
import { Card, CardHeader, IconButton } from "@mui/material";
import { Copyright } from "../../components";
import { FaSearch } from "react-icons/fa";
import { useGetAllPlayers } from "../../endpoints/useGetAllPlayers";
import Page from "../../types/page";
import { usePage } from "../../hooks/usePage";
import {
  PlayerData,
  PlayerSummaryResult,
  GetPlayerSummaries,
} from "../../endpoints/useGetPlayerSummaries";
import { GetPlayerOutput } from "../../endpoints/useGetPlayer";

const ROOT = makeRootClassName("PlayersPage");
const el = makeElementClassNameFactory(ROOT);

export function PlayersPage(): ReactElement {
  const [data, setData] = useState<PlayerData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { setPage, setPageProps } = usePage();

  async function FetchData() {
    const response: { data: { players: GetPlayerOutput[] } } =
      await useGetAllPlayers();
    const steamIds: string[] = response.data.players.map(
      (x: GetPlayerOutput) => x.steamId,
    );

    const summaryResponse = await GetPlayerSummaries({
      steamIds: steamIds,
    });

    if (summaryResponse && summaryResponse.data) {
      const playerOutputMap = new Map(
        response.data.players.map((playerOutput) => [
          playerOutput.steamId,
          playerOutput,
        ]),
      );

      const combinedData: PlayerData[] = summaryResponse.data.players.map(
        (summary: PlayerSummaryResult) => {
          let playerOutput = playerOutputMap.get(summary.steamId);
          let trustFactorChecked = playerOutput ? playerOutput.trustFactor : 0;
          playerOutput = playerOutput
            ? playerOutput
            : {
                steamId: "",
                trustFactor: trustFactorChecked,
                firstTimeSeen: "",
                suspect: false,
              };

          return {
            ...summary,
            ...playerOutput,
            trustFactor: trustFactorChecked,
          };
        },
      );

      setData(combinedData);
    }
    setLoading(false);
  }

  useEffect(() => {
    FetchData();
  }, []);

  const onInspect = (player: PlayerData) => {
    setPageProps({ player });
    setPage(Page.Player);
  };

  const timeCreatedSortingFunc = useMemo(() => {
    return (
      rowA: MRT_Row<PlayerData>,
      rowB: MRT_Row<PlayerData>,
      columnId: string,
    ) => {
      const timeCreatedA = rowA._valuesCache.timeCreated;
      const timeCreatedB = rowB._valuesCache.timeCreated;

      if (timeCreatedA === timeCreatedB) {
        return 0;
      } else {
        return timeCreatedA > timeCreatedB ? 1 : -1;
      }
    };
  }, []);

  const firstSeenSortingFunc = useMemo(() => {
    return (
      rowA: MRT_Row<PlayerData>,
      rowB: MRT_Row<PlayerData>,
      columnId: string,
    ) => {
      const timeCreatedA = rowA._valuesCache.firstTimeSeen;
      const timeCreatedB = rowB._valuesCache.firstTimeSeen;

      if (timeCreatedA === timeCreatedB) {
        return 0;
      } else {
        return timeCreatedA > timeCreatedB ? 1 : -1;
      }
    };
  }, []);

  const columns = useMemo<MRT_ColumnDef<PlayerData>[]>(
    () => [
      {
        id: "avatar",
        header: " ",
        accessorFn: (row) => (
          <a target="_blank" href={row.profileUrl}>
            <img src={row.avatar} />
          </a>
        ),
        size: 1,
      },
      {
        id: "name",
        header: "Name",
        accessorFn: (row) => row.personaName,
      },
      {
        id: "steamId",
        header: "Steam ID",
        accessorFn: (row) => row.steamId,
        size: 1,
      },
      {
        id: "timeCreated",
        header: "Time Created",
        accessorFn: (row) =>
          row.timeCreated > 0
            ? new Date(row.timeCreated * 1000).toUTCString()
            : "N/A",
        sortType: timeCreatedSortingFunc,
      },
      {
        id: "firstTimeSeen",
        header: "First Seen",
        accessorFn: (row) =>
          Number(row.firstTimeSeen) > 0
            ? new Date(Number(row.firstTimeSeen) * 1000).toUTCString()
            : "N/A",
        sortType: firstSeenSortingFunc,
      },
      {
        id: "trustFactor",
        header: "Trust Factor",
        accessorFn: (row) =>
          row.trustFactor + (row.suspect ? " | Suspect" : ""),
      },
    ],
    [],
  );

  return (
    <div className={ROOT}>
      <Card>
        <CardHeader
          title="Players"
          subheader="All players ever connected to our services."
        />
      </Card>
      <MaterialReactTable
        columns={columns}
        data={data}
        pageCount={10}
        positionGlobalFilter="left"
        state={{ isLoading: loading, showGlobalFilter: true }}
        muiSearchTextFieldProps={{
          placeholder: "Search all players",
          sx: { minWidth: "300px", padding: "8px" },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            header: "",
          },
        }}
        enableRowActions
        renderRowActions={({ row, table }) => (
          <IconButton
            onClick={() => {
              if (data !== undefined) {
                onInspect(data[row.index]);
              }
            }}
          >
            <FaSearch size={15} />
          </IconButton>
        )}
        initialState={{
          density: "compact",
        }}
        enableDensityToggle={false}
        enableColumnFilters={false}
        enableHiding={false}
      />
      <Copyright absolute />
    </div>
  );
}
