import { ReactElement } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { Button } from "@mui/material";
import { FaArrowLeft } from "react-icons/fa";
import { Copyright } from "../../components";
import { PiSmileySadFill } from "react-icons/pi";
import { useSiteTheme } from "../../hooks/useSiteTheme";

const ROOT = makeRootClassName("FileNotFoundPage");
const el = makeElementClassNameFactory(ROOT);

export function FileNotFoundPage(): ReactElement {
  const { theme } = useSiteTheme();

  return (
    <div>
      <div className={ROOT}>
        <span className={el`iconLabel`}>
          <PiSmileySadFill fill={theme.palette.primary.main} size={50} />
          <h1>Uh oh, that page cannot be found! (404)</h1>
        </span>
        <Button variant="outlined" startIcon={<FaArrowLeft size={20} />}>
          <p className={el`button-text`}>Go Home</p>
        </Button>
      </div>
      <Copyright absolute />
    </div>
  );
}
