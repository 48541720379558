import { Layout, SPAProvider, SPARouter } from "./features";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App = () => {
  return (
    <>
      <SPAProvider>
        <GoogleOAuthProvider clientId="795041399230-engk72v4meqf6pca283ca71g75rio05h.apps.googleusercontent.com">
          <Layout>
            <SPARouter />
          </Layout>
        </GoogleOAuthProvider>
      </SPAProvider>
    </>
  );
};

export default App;
