import { ApiPost } from "./api";

export type RconCommunicationOutput = {
  response?: any;
};

export type RconCommunicationInput = {
  ip?: string;
  port?: number;
  password?: string;
  command: string;
  sendToAll?: boolean;
};

export const RconCommunication = async (input?: RconCommunicationInput) => {
  let endPoint = "RconCommunication";
  if (input?.password === undefined) {
    if (input?.sendToAll) {
      endPoint += "/PostToAllServers";
    } else {
      endPoint += "/PostbyIp";
    }
  }
  
  if (input != null)
    input.command = "abapi_" + input.command; // so the RCON knows to disconnect after accepting a command. 

  const response = await ApiPost(endPoint, input);

  let data;
  if (response && response.data && response.status == 200) {
    data = response.data as RconCommunicationOutput;
  }

  return { data };
};
