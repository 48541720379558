import { useState } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { RCONInitConsolePacket } from "../../types/rcon";

const ROOT = makeRootClassName("RCONConsole");
const el = makeElementClassNameFactory(ROOT);

type ConsoleInputProps = {
  config?: RCONInitConsolePacket;
  onSubmit: (cmd: string) => void;
};

export const ConsoleInput = (props: ConsoleInputProps) => {
  const [inputValue, setInputValue] = useState<string>("");

  const onSubmit = (e: any) => {
    e.preventDefault();
    props.onSubmit(e.target[0].value);
    setInputValue("");
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <Autocomplete
          value={inputValue}
          onChange={(event, newValue) => {
            setInputValue(newValue != null ? newValue : "");
          }}
          renderInput={(params: any) => (
            <TextField {...params} label={""} variant={"standard"} />
          )}
          options={props.config?.Commands ?? []}
          clearOnBlur
          handleHomeEndKeys
          selectOnFocus
          freeSolo
        />
      </form>
    </div>
  );
};
