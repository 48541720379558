import { ReactElement } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { Card, CardHeader, Divider } from "@mui/material";
import { StatCard, DataChart, Copyright } from "../../components";
import { FaBandAid, FaHeart, FaUsers, FaWrench } from "react-icons/fa";
import { GiHealthNormal, GiHighShot, GiPistolGun } from "react-icons/gi";
import { RiKnifeBloodFill } from "react-icons/ri";

const ROOT = makeRootClassName("DashboardPage");
const el = makeElementClassNameFactory(ROOT);

export function DashboardPage(): ReactElement {
  return (
    <div className={ROOT}>
      <div className={el`header-cards`}>
        <Card>
          <CardHeader title="Dashboard" />
        </Card>
        <Divider />
        <Card>
          <CardHeader title="Activity" />
          <div className={el`activity-stats`}>
            <DataChart label="Last 10 Days" />
            <DataChart label="Last 48 Hours" hours />
          </div>
        </Card>
        <Card>
          <CardHeader title="Statistics" />
          <div className={el`stats`}>
            <StatCard title="Players" value={0} icon={FaUsers} fake />
            <StatCard title="Shots Fired" value={0} icon={GiHighShot} fake />
            <StatCard title="Kills" value={0} icon={GiPistolGun} fake />
            <StatCard title="Deaths" value={0} icon={RiKnifeBloodFill} fake />
            <StatCard title="Spawns" value={0} icon={FaHeart} fake />
            <StatCard title="Bandages Used" value={0} icon={FaBandAid} fake />
            <StatCard title="Revives" value={0} icon={GiHealthNormal} fake />
            <StatCard title="Items Crafted" value={0} icon={FaWrench} fake />
          </div>
        </Card>
      </div>
      <Copyright absolute />
    </div>
  );
}
