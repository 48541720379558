import { ReactElement, useEffect, useRef, useState } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { BiMenu } from "react-icons/bi";
import Page from "../../types/page";
import { SitePages } from "../../pages";
import { usePage } from "../../hooks/usePage";
import { Menu, MenuProps } from "antd";
import { useSiteTheme } from "../../hooks/useSiteTheme";
import clsx from "clsx";

const ROOT = makeRootClassName("Sidebar");
const el = makeElementClassNameFactory(ROOT);

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group" | "divider",
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export function Sidebar(): ReactElement {
  const { setPage } = usePage();
  const { theme } = useSiteTheme();
  const [selectedItem, setSelectedItem] = useState("0");
  const sideBarRef = useRef<HTMLDivElement>(null);

  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => setCollapsed((prev) => !prev);

  const { page: currentpage } = usePage();

  const handleSidebarChange = (_page: Page) => {
    setPage(_page);
    setSelectedItem(_page.toString());
  };

  useEffect(() => {
    const onScrollEvent = () => {
      if (sideBarRef.current) {
        sideBarRef.current.style.marginTop = `${window.scrollY + 16}px`;
      }
    };

    window.addEventListener("scroll", onScrollEvent);

    return () => {
      window.removeEventListener("scroll", onScrollEvent);
    };
  }, []);

  const items: MenuItem[] = [];
  const groups: { [key: string]: MenuItem } = {};

  items.push(getItem(!collapsed && "Reign of Dwarf", "collapse", <BiMenu />));

  items.push(getItem("", "divider", undefined, undefined, "group"));

  // get groups first
  for (let i = 0; i < SitePages.length; i++) {
    const page = SitePages[i];
    const { group } = page;

    if (group) {
      if (!groups[group]) {
        groups[group] = getItem(group, group, undefined, [], "group");
      }
    }
  }

  // now get items
  for (let i = 0; i < SitePages.length; i++) {
    const page = SitePages[i];
    const { group } = page;

    if (group) {
      let gp = groups[group] as MenuItem;
      if (gp && "children" in gp) {
        if (page.hideInNav) continue;
        gp.children?.push(getItem(page.displayText, page.page, <page.icon />));
      }
    } else {
      if (page.hideInNav) continue;
      items.push(getItem(page.displayText, page.page, <page.icon />));
    }
  }

  // add groups to items
  for (const groupKey in groups) {
    if (Object.prototype.hasOwnProperty.call(groups, groupKey)) {
      const group = groups[groupKey];
      items.push(group);
    }
  }

  return (
    <div
      className={clsx(ROOT, {
        [el`collapsed`]: !collapsed,
      })}
      ref={sideBarRef}
    >
      <Menu
        className={el`menu`}
        selectedKeys={[selectedItem]}
        mode="vertical"
        theme={theme.palette.mode}
        inlineCollapsed={collapsed}
        items={items}
        onClick={(item) => {
          if (item.key === "collapse") toggleCollapsed();
          else {
            const _page = item.key as unknown as Page;
            handleSidebarChange(_page);
          }
        }}
      />
    </div>
  );
}
