import { ReactElement } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { GoogleOneTap } from "../../features";
import { Copyright } from "../../components";

const ROOT = makeRootClassName("NoAccessPage");
const el = makeElementClassNameFactory(ROOT);

export function NoAccessPage(): ReactElement {
  return (
    <div className={ROOT}>
      <div className={el`container`}>
        <GoogleOneTap />
      </div>
      <Copyright absolute />
    </div>
  );
}
