import { api } from "./api";

export type ApiAdminUserAuthInput = {
  credential?: string;
};

export const Authenticate = async (input: ApiAdminUserAuthInput) => {
  const fetchData = async () => {
    const response = await api.post("/AdminUserAuth/authenticate", {
      credential: input.credential,
    });

    return response;
  };

  const response = await fetchData();
  let data;
  if (response && response.data) {
    data = response.data;
  }

  return { data };
};
