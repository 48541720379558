import { ReactElement, useEffect, useMemo, useState } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import {
  CheatingReportResult,
  GetAllCheatingReports,
} from "../../endpoints/useGetAllCheatingReports";
import { MRT_ColumnDef, MaterialReactTable } from "material-react-table";
import { Card, CardHeader, IconButton } from "@mui/material";
import { Copyright } from "../../components";
import { FaEye } from "react-icons/fa";

const ROOT = makeRootClassName("PlayerReportsPage");
const el = makeElementClassNameFactory(ROOT);

export function PlayerReportsPage(): ReactElement {
  const [data, setData] = useState<CheatingReportResult[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function FetchData() {
      const response = await GetAllCheatingReports({ sinceWhen: 0 });
      if (response && response.data) {
        setData(response.data.response.results);
      }
      setLoading(false);
    }
    FetchData();
  }, []);

  const columns = useMemo<MRT_ColumnDef<CheatingReportResult>[]>(
    () => [
      {
        id: "steamId",
        header: "Steam ID",
        accessorFn: (row) => row,
      },
      {
        id: "reporterSteamId",
        header: "Reporter Steam ID",
        accessorFn: (row) => row.reportid,
      },
      {
        id: "reason",
        header: "Reason",
        accessorFn: (row) => row.playerreport,
      },
      {
        id: "date",
        header: "Date",
        accessorFn: (row) => row.timereport,
      },
    ],
    [],
  );

  return (
    <div className={ROOT}>
      <Card>
        <CardHeader
          title="Player Reports"
          subheader="All players reported for abusing our services."
        />
      </Card>
      <MaterialReactTable
        columns={columns}
        data={data}
        positionGlobalFilter="left"
        state={{ isLoading: loading, showGlobalFilter: true }}
        muiSearchTextFieldProps={{
          placeholder: "Search all reports",
          sx: { minWidth: "300px", padding: "8px" },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            header: "",
          },
        }}
        enableRowActions
        renderRowActions={({ row, table }) => (
          <IconButton onClick={() => console.log(row)}>
            <FaEye size={20} />
          </IconButton>
        )}
        initialState={{
          density: "compact",
        }}
        enableDensityToggle={false}
        enableColumnFilters={false}
        enableHiding={false}
      />
      <Copyright absolute />
    </div>
  );
}
