import { ReactElement } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { StyleProps } from "../../types/props";
import { faker } from "@faker-js/faker";
import Chart from "react-apexcharts";

const ROOT = makeRootClassName("DataChart");
const el = makeElementClassNameFactory(ROOT);

type DataChartProps = StyleProps & {
  label?: string;
  hours?: boolean;
};

export function DataChart(props: DataChartProps): ReactElement {
  const fakeRandomNumberArray = (
    length: number,
    min: number,
    max: number,
  ): number[] => {
    return Array.from({ length: length }, () =>
      faker.number.int({ min: min, max: max }),
    );
  };

  const lastTenDays = (): string[] => {
    let dates: string[] = [];
    for (let i = 10; i > 0; i--) {
      let date = new Date();
      date.setDate(date.getDate() - i);
      let formattedDate =
        date.toLocaleString("default", { month: "short" }) +
        " " +
        date.getDate();
      dates.push(formattedDate);
    }
    return dates;
  };

  const lastFortyEightHours = (): string[] => {
    let dates: string[] = [];
    for (let i = 48; i > 0; i--) {
      let date = new Date();
      date.setHours(date.getHours() - i);
      let formattedDate = date.toLocaleString("default", {
        hour: "numeric",
        hour12: true,
      });
      dates.push(formattedDate);
    }
    return dates;
  };

  const data = {
    options: {
      chart: {
        id: "apexchart-example",
      },
      xaxis: {
        categories: props.hours ? lastFortyEightHours() : lastTenDays(),
      },
      colors: ["#D74452"],
    },
    series: [
      {
        name: "series-1",
        data: fakeRandomNumberArray(props.hours ? 48 : 10, 9000, 14000),
      },
    ],
  };

  return (
    <div className={ROOT}>
      <h1 className={el`label`}>{props.label}</h1>
      <Chart
        options={data.options}
        series={data.series}
        type="line"
        width={500}
        height={320}
      />
    </div>
  );
}
