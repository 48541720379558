import { useEffect, useReducer, useState } from "react";
import { Theme, createTheme } from "@mui/material/styles";

export const useSiteTheme = () => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#D74452",
      },
    },
    typography: {
      fontFamily: ["Poppins", "Montserrat"].join(","),
    },
  });

  const lightTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#D74452",
      },
    },
    typography: {
      fontFamily: ["Poppins", "Montserrat"].join(","),
    },
  });

  const [theme, setTheme] = useState<Theme>(darkTheme);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const toggleTheme = () => {
    localStorage.setItem(
      "theme",
      theme.palette.mode === "light" ? "dark" : "light",
    );
    setTheme((prev) =>
      prev.palette.mode === "light" ? darkTheme : lightTheme,
    );
    forceUpdate();
  };

  useEffect(() => {
    const localTheme = localStorage.getItem("theme");
    if (localTheme === "light") {
      setTheme(lightTheme);
    } else {
      setTheme(darkTheme);
    }
  }, []);

  return { theme, toggleTheme, ignored };
};
