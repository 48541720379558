import { RconCommunication } from "./useRconCommunication";

export type KickServerPlayerInput = {
  serverIp: string;
  serverPort: number;
  steamId: number;
};

export const KickServerPlayer = async (input: KickServerPlayerInput) => {
  const response = await RconCommunication({
    ip: input.serverIp,
    port: input.serverPort,
    command: `player-kick ${input.steamId}`,
  });

  let data;
  if (response && response.data?.response) data = response.data.response;

  return data;
};
