import { ReactElement, useRef, useState } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { Copyright, RCONConsole } from "../../components";
import { Button, Input } from "@mui/material";
import { RconCommunication } from "../../endpoints/useRconCommunication";
import {RCONPlayerData} from "../../types/rcon";

const ROOT = makeRootClassName("RconPage");
const el = makeElementClassNameFactory(ROOT);

export type RconPageProps = {};

export function RconPage(props: RconPageProps): ReactElement {
  const [loginAddress, setLoginAddress] = useState<string | null>(null);
  const [loginPassword, setLoginPassword] = useState<string | null>(null);

  const addressRef = useRef<HTMLInputElement>(null);
  const sendAllCommand = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const onSubmitLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      (addressRef.current?.value !== null ||
        addressRef.current?.value !== "") &&
      (passwordRef.current?.value !== null || passwordRef.current?.value !== "")
    ) {
      setLoginAddress(addressRef.current!.value);
      setLoginPassword(passwordRef.current!.value);
    } else {
      setLoginAddress("127.0.0.1:27015");
      setLoginPassword("password");
    }
  };

  const onPressDisconnect = () => {
    setLoginAddress(null);
    setLoginPassword(null);
  };

  const onPressSendToAll = () => {
    const command = sendAllCommand.current?.value;
    if (typeof command === "undefined") return;
    if (command!.trim().length === 0) return;
    if (
      !window.confirm(
        `Are you sure you want to send '${command}' to all active servers?`,
      )
    )
      return;
    RconCommunication({
      sendToAll: true,
      command,
    });
  };

  const loginSection = () => {
    return (
      <form className={el`login-form`} onSubmit={onSubmitLogin}>
        <div className={el`login-inputs`}>
          <Input
            inputRef={addressRef}
            key={"address"}
            placeholder={"127.0.0.1:27014"}
          />
          <Input
            className={el`login-input`}
            inputRef={passwordRef}
            key={"password"}
            placeholder={"password"}
            type="password"
          />
        </div>
        <Button
          className={el`input-submit`}
          variant={"outlined"}
          type={"submit"}
        >
          Connect
        </Button>
      </form>
    );
  };
  const consoleSection = () => {
    return (
      <div className={el`console-section`}>
        <RCONConsole
          connectionAddr={loginAddress?.split(":")[0] ?? ""}
          connectionPort={Number(loginAddress?.split(":")[1])}
          connectionPassword={loginPassword ?? "password"}
          setPlayerData={(playerData: RCONPlayerData[]) => {}}
        />
        <Button className={el`disconnect`} onClick={() => onPressDisconnect()}>
          Disconnect
        </Button>
      </div>
    );
  };

  const sendToAll = () => {
    return (
      <div className={el`send-to-all`}>
        <form onSubmit={onSubmitLogin}>
          <div>
            <Input
              inputRef={sendAllCommand}
              key={"command"}
              placeholder={"say example"}
            />
          </div>
        </form>
        <Button variant={'outlined'} className={el`input-submit`} onClick={() => onPressSendToAll()}>
          Send to ALL
        </Button>
      </div>
    );
  };

  return (
    <div className={ROOT}>
      <div className={el`center-me`}>
        {loginAddress != null ? consoleSection() : loginSection()}
        <span className={el`divider`}></span>
        {sendToAll()}
      </div>
      <Copyright absolute />
    </div>
  );
}
