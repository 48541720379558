import { ApiGet } from "./api";
import { CheatingReportResult } from "./useGetAllCheatingReports";

export type GetReportsForPlayerOutput = {
  response: {
    results: CheatingReportResult[];
  };
};

export type GetReportsForPlayerInput = {
  steamId?: string;
  sinceWhen?: number;
  untilWhen?: number;
  includeReports?: boolean;
  includeBans?: boolean;
};

export const GetReportsForPlayer = async (input?: GetReportsForPlayerInput) => {
  const response = await ApiGet(
    "/GetCheatingReports/GetReportForPlayerTimeRange",
    input && {
      steamId: input.steamId,
      includeBans: input.includeBans,
      includeReports: input.includeReports,
      sinceWhen: input.sinceWhen,
      untilWhen: input.untilWhen,
    },
  );

  let data;
  if (response && response.data) {
    data = response.data as GetReportsForPlayerOutput;
  }

  return { data };
};
