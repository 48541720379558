import { ApiGet } from "./api";
import { GetPlayerOutput } from "./useGetPlayer";

export type PlayerSummaryResult = {
  steamId: string;
  communityVisibilityState: number;
  profileState: number;
  personaName: string;
  commentPermission: number;
  profileUrl: string;
  avatar: string;
  avatarMedium: string;
  avatarFull: string;
  avatarHash: string;
  personaState: number;
  primaryClanId: string;
  timeCreated: number;
  personaStateFlags: number;
};

export type PlayerData = GetPlayerOutput & PlayerSummaryResult;

export type GetPlayerSummariesResponse = {
  response: {
    players: PlayerSummaryResult[];
  };
};

export type GetPlayerSummariesOutput = {
  players: PlayerSummaryResult[];
};

export type GetPlayerSummariesInput = {
  steamIds: string[];
};

const chunk = (arr: string[], size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size),
  );

export const GetPlayerSummaries = async (input?: GetPlayerSummariesInput) => {
  if (!input || !input.steamIds || input.steamIds.length == 0)
    throw "Input missing or invalid";

  const chunks = chunk(input.steamIds, 100);
  const promises = chunks.map((chunk) => {
    const steamIdString = chunk.join(",");
    return ApiGet("/GetPlayerSummaries", { steamIds: steamIdString });
  });

  const responses = await Promise.all(promises);

  const data: GetPlayerSummariesOutput = {
    players: [],
  };

  if (responses && Array.isArray(responses)) {
    responses.forEach((response) => {
      if (response && response.data) {
        const responseData: GetPlayerSummariesResponse =
          response.data as GetPlayerSummariesResponse;
        data.players.push(...responseData.response.players);
      }
    });
  }

  return { data };
};
