import axios from "axios";

export const api = axios.create({
  baseURL: "https://api.reignofdwarf.com/api",
});

const IsAuthed = async (): Promise<{ success: boolean; token?: string }> => {
  const token = localStorage.getItem("jwt");
  if (!token) {
    return {
      success: false,
    };
  }

  let success = true;
  if (IsTokenExpiring(token)) success = await RefreshToken(token);

  return {
    success,
    token,
  };
};

export const IsTokenExpiring = (accessToken: string) => {
  const payload = JSON.parse(atob(accessToken.split(".")[1]));

  const now = Math.floor(Date.now() / 1000);
  const bufferSeconds = 60;

  return payload.exp <= now + bufferSeconds;
};

export const RefreshToken = async (accessToken: string): Promise<boolean> => {
  try {
    const response = await api.post("/AdminUserAuth/refresh-token", {
      accessToken,
    });

    if (response.status !== 200) {
      localStorage.removeItem("jwt");
      window.location.reload();
      return false;
    }

    if (response && response.data && response.data.token) {
      console.log("Updating token to new JTW: " + response.data.token);
      localStorage.setItem("jwt", response.data.token);
    } else {
      localStorage.removeItem("jwt");
      window.location.reload();
      return false;
    }

    if (IsTokenExpiring(response.data.token)) return false;

    return response?.data?.token;
  } catch (error) {
    localStorage.removeItem("jwt");
    window.location.reload();
    return false;
  }
};

export const ApiGet = async (url: string, input?: any) => {
  const { success, token } = await IsAuthed();
  if (!success) return;

  return api.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "text/plain",
    },
    params: input,
  });
};

export const ApiPost = async (url: string, input: any) => {
  const { success, token } = await IsAuthed();
  if (!success) return;

  return api.post(url, null, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "text/plain",
    },
    params: input,
  });
};

export const ApiPut = async (url: string, input: any) => {
  const { success, token } = await IsAuthed();
  if (!success) return;

  return api.put(url, null, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "text/plain",
    },
    params: input,
  });
};
