export const useAdmin = () => {
  let isAdmin = false;

  const _jwt = localStorage.getItem("jwt");
  if (_jwt) {
    isAdmin = true;
  }

  return { isAdmin };
};
