import { IconType } from "react-icons";
import Page from "../types/page";
import { DashboardPage } from "./DashboardPage";
import { BiSolidDashboard, BiSolidReport } from "react-icons/bi";
import { FaHammer, FaServer, FaUsers } from "react-icons/fa";
import { PlayersPage } from "./PlayersPage";
import { ServersPage } from "./ServersPage";
import { ServerPage } from "./ServerPage";
import { RconPage } from "./RconPage";
import { HiCommandLine } from "react-icons/hi2";
import { PlayerPage } from "./PlayerPage";
import { PlayerBansPage } from "./PlayerBansPage";

type PageData = {
  displayText: string;
  page: Page;
  component: JSX.Element;
  icon: IconType;
  group?: string;
  hideInNav?: boolean;
};

export const SitePages: PageData[] = [
  {
    displayText: "Dashboard",
    page: Page.Dashboard,
    component: <DashboardPage />,
    icon: BiSolidDashboard,
  },
  {
    displayText: "Player List",
    page: Page.Players,
    component: <PlayersPage />,
    icon: FaUsers,
    group: "Players",
  },
  {
    displayText: "Player",
    page: Page.Player,
    component: <PlayerPage />,
    icon: FaUsers,
    group: "Players",
    hideInNav: true,
  },
  // {
  //   displayText: "Player Reports",
  //   page: Page.PlayerReports,
  //   component: <PlayerReportsPage />,
  //   icon: BiSolidReport,
  //   group: "Players",
  // },
  // {
  //   displayText: "Player Bans",
  //   page: Page.PlayerBans,
  //   component: <PlayerBansPage />,
  //   icon: FaHammer,
  //   group: "Players",
  // },
  {
    displayText: "Server List",
    page: Page.Servers,
    component: <ServersPage />,
    icon: FaServer,
    group: "Servers",
  },
  {
    displayText: "Remote Console",
    page: Page.RCON,
    component: <RconPage />,
    icon: HiCommandLine,
    group: "Servers",
  },
  {
    displayText: "Server",
    page: Page.Server,
    component: <ServerPage />,
    icon: FaServer,
    group: "Servers",
    hideInNav: true,
  },
  // {
  //   displayText: "Server Reports",
  //   page: Page.ServerReports,
  //   component: <PlayerReportsPage />,
  //   icon: BiSolidReport,
  //   group: "Servers",
  // },
  // {
  //   displayText: "Server Bans",
  //   page: Page.ServerBans,
  //   component: <PlayerBansPage />,
  //   icon: FaHammer,
  //   group: "Servers",
  // },
];
