import { ReactElement, useEffect, useRef, useState } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { StyleProps } from "../../types/props";
import {ConsoleLog, RCONInitConsolePacket, RCONPlayerData} from "../../types/rcon";
import { ConsoleInput } from "./ConsoleInput";
import { RconCommunication } from "../../endpoints/useRconCommunication";

const ROOT = makeRootClassName("RCONConsole");
const el = makeElementClassNameFactory(ROOT);

type RCONConsoleProps = {
  connectionAddr: string;
  connectionPort: number;
  connectionPassword?: string;
  setPlayerData: (playerData: RCONPlayerData[]) => void;
} & StyleProps;

export function RCONConsole(props: RCONConsoleProps): ReactElement {
  const [commandHistory, setCommandHistory] = useState<ConsoleLog[]>([]);
  const consoleRef = useRef<HTMLDivElement>(null);
  const [getIsRunningCommand, setIsRunningCommand] = useState(false);
  const [rconConfig, setRconConfig] = useState<RCONInitConsolePacket>();

  const logOutput = (
    logValue: string,
    logType: "output" | "input" | "error",
  ) => {
    let log: ConsoleLog = {
      logValue: logValue,
      logType: logType,
    };
    setCommandHistory((prev) => [...prev, log]);
  };

  const handleCommand = (command: string) => {
    if (command === "") return;
    if (command === "clear") {
      setCommandHistory([]);
      return;
    }

    let typedCommand: ConsoleLog = {
      logValue: command,
      logType: "input",
    };
    setCommandHistory((prev: ConsoleLog[]) => [...prev, typedCommand]);

    const runCmd = async (cmd: string) => {
      if (getIsRunningCommand) return;
      setIsRunningCommand(true);
      const data = await RconCommunication({
        ip: props.connectionAddr,
        port: props.connectionPort,
        password: props.connectionPassword,
        command: cmd,
      });
      if (data && data.data) {
        let result: ConsoleLog = {
          logValue: data.data.response ?? "",
          logType: "output",
        };
        setCommandHistory((prev: ConsoleLog[]) => [...prev, result]);
      }
      setIsRunningCommand(false);
    };

    runCmd(command);
  };

  // auto scroll  to bottom
  useEffect(() => {
    if (consoleRef.current) {
      consoleRef.current.scrollTop = consoleRef.current.scrollHeight;
    }
  }, [commandHistory]);

  // initialize rcon service
  useEffect(() => {
    const connectRcon = async () => {
      setIsRunningCommand(true);
      const commandsData = await RconCommunication({
        ip: props.connectionAddr,
        port: props.connectionPort,
        password: props.connectionPassword,
        command: "init-console",
      });
      setIsRunningCommand(false);

      if (commandsData && commandsData.data) {
        if (commandsData.data.response.startsWith("Invalid")) {
          logOutput(
            `Failed to connect: ${commandsData.data.response}`,
            "error",
          );
          return;
        }

        const rconConfig = JSON.parse(
          commandsData.data.response,
        ) as RCONInitConsolePacket;
        logOutput(
          `Connected & loaded ${rconConfig.Commands.length} commands!`,
          "error",
        );
        setRconConfig(rconConfig);
        props.setPlayerData(rconConfig.Players);
      }
    };

    logOutput(`Attempting connection to ${props.connectionAddr}`, "error");
    connectRcon();
  }, []);

  return (
    <div className={ROOT}>
      <div className={el`rcon-console`} ref={consoleRef}>
        {commandHistory.map((command: ConsoleLog, index: number) => (
          <div key={index} className={el`rcon-command`}>
            <p className={el`rcon-command-` + command.logType}>
              {(command.logType === "input" ? "> " : "") + command.logValue}
            </p>
          </div>
        ))}
      </div>

      <ConsoleInput config={rconConfig} onSubmit={handleCommand} />
    </div>
  );
}
