import { ApiGet } from "./api";

export type GetServerOutput = {
  realServerIP: string;
  serverSteamID: string;
  name: string;
  firstTimeSeen: string;
  official: boolean;
  adminsSteamID: string;
  addr: string;
  gamePort: number;
  rconPort: number;
  appId: number;
  gameDir: string;
  version: string;
  product: string;
  region: number;
  players: number;
  maxPlayers: number;
  bots: number;
  map: string;
  secure: boolean;
  dedicated: boolean;
  os: string;
};

export type GetServersOutput = {
  unmatchedServers: GetServerOutput[];
  matchedServers: GetServerOutput[];
};

export const GetServers = async () => {
  const response = await ApiGet("/BackendGameServers");
  let data;
  if (response && response.data) {
    data = response.data;
  }

  data = data as GetServersOutput;

  return { data };
};
