import { ReactElement, useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { useSiteTheme } from "../../hooks/useSiteTheme";
import { ThemeChanger } from "../../components";
import { ConfigProvider } from "antd";

type ThemeHelperProps = {
  children: ReactElement;
};

export function ThemeHelper(props: ThemeHelperProps): ReactElement {
  const { theme, toggleTheme } = useSiteTheme();
  const [key, setKey] = useState(0);

  useEffect(() => {
    document.documentElement.setAttribute("style", theme.palette.mode);
  }, [theme]);

  const toggleThemeAction = () => {
    toggleTheme();
    setKey((prevKey) => prevKey + 1);
  };

  return (
    <ThemeProvider key={key} theme={theme}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: theme.palette.primary.main,
            borderRadius: 2,
            colorBgBase: theme.palette.background.default,
            colorText: theme.palette.text.primary,
          },
        }}
      >
        {props.children}
        <ThemeChanger toggleTheme={toggleThemeAction} />
      </ConfigProvider>
    </ThemeProvider>
  );
}
