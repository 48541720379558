import { ReactElement } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { StyleProps } from "../../types/props";
import { IconType } from "react-icons/lib";
import CountUp from "react-countup";
import { faker } from "@faker-js/faker";

const ROOT = makeRootClassName("StatCard");
const el = makeElementClassNameFactory(ROOT);

type StatCardProps = StyleProps & {
  title: string;
  value: number;
  icon?: IconType;
  fake?: boolean;
};

export function StatCard(props: StatCardProps): ReactElement {
  const { fake } = props;
  const value = fake
    ? faker.number.int({ min: 10000, max: 50000 })
    : props.value;

  return (
    <div className={ROOT}>
      <div className={el`header`}>
        {props.icon && <props.icon className={el`icon`} size={20} />}
        <p className={el`title`}>{props.title}</p>
      </div>
      <CountUp className={el`value`} end={value} duration={2} />
    </div>
  );
}
