import { useEffect, useMemo, useRef, useState } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import { usePage } from "../../hooks/usePage";
import { Button, Card, TextField } from "@mui/material";
import Page from "../../types/page";
import { FaCalendar, FaClipboard, FaUser } from "react-icons/fa";
import { PlayerData } from "../../endpoints/useGetPlayerSummaries";
import { CheatingReportResult } from "../../endpoints/useGetAllCheatingReports";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { SimpleModal } from "../../components";
import { SetPlayerTrustFactor } from "../../endpoints/useSetPlayerTrustFactor";
import { GetReportsForPlayer } from "../../endpoints/useGetReportsForPlayer";

const ROOT = makeRootClassName("PlayerPage");
const el = makeElementClassNameFactory(ROOT);

export function PlayerPage() {
  const [data, setData] = useState<PlayerData>();
  const [reportsData, setReportsData] = useState<CheatingReportResult[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const trustFactorRef = useRef<HTMLInputElement>();
  const { pageProps, setPage } = usePage();

  async function FetchData() {
    const player = pageProps.player as PlayerData;
    setData(player);

    const reportsResponse = await GetReportsForPlayer({
      steamId: player.steamId,
      includeReports: true,
      includeBans: true,
      sinceWhen: 0o000000000,
      untilWhen: 9999999999,
    });

    setReportsData(reportsResponse.data?.response.results);
    setLoading(false);
  }

  useEffect(() => {
    if (!pageProps?.player) {
      setPage(Page.Players);
      return;
    }
    FetchData();
  }, []);

  const infoCard = (): JSX.Element => {
    return (
      <Card>
        <div className={el`profile`}>
          <img src={data?.avatarFull} />
          <div className={el`profileInfoPanel`}>
            <div className={el`profileInfo`}>
              <span className={el`iconLabel`}>
                <FaUser />
                <span>
                  <p className={el`highlight`}>Display Name</p>
                  <p>{data?.personaName}</p>
                </span>
              </span>
              <span className={el`iconLabel`}>
                <FaCalendar />
                <span>
                  <p className={el`highlight`}>First Seen</p>
                  <p>
                    {Number(data?.firstTimeSeen) > 0
                      ? new Date(
                          Number(data?.firstTimeSeen) * 1000,
                        ).toUTCString()
                      : "N/A"}
                  </p>
                </span>
              </span>
              <span className={el`iconLabel`}>
                <AiFillSafetyCertificate />
                <span>
                  <p className={el`highlight`}>Trust Factor</p>
                  <a target="_blank">{data?.trustFactor}</a>
                </span>
              </span>
            </div>
            <div className={el`profileInfo`}>
              <span className={el`iconLabel`}>
                <FaUser />
                <span>
                  <p className={el`highlight`}>SteamID64</p>
                  <p>{data?.steamId}</p>
                </span>
              </span>
              <span className={el`iconLabel`}>
                <FaCalendar />
                <span>
                  <p className={el`highlight`}>Account Created</p>
                  <p>
                    {Number(data?.timeCreated) > 0
                      ? new Date(Number(data?.timeCreated) * 1000).toUTCString()
                      : "N/A"}
                  </p>
                </span>
              </span>
              <span className={el`iconLabel`}>
                <AiFillSafetyCertificate />
                <span>
                  <p className={el`highlight`}>Is Suspect</p>
                  <a target="_blank">
                    {data?.suspect ? "Is Suspect" : "Is NOT Suspect"}
                  </a>
                </span>
              </span>
            </div>
          </div>
        </div>
      </Card>
    );
  };

  const actionsCard = (): JSX.Element => {
    const onUpdateTrustFactor = async () => {
      if (data?.steamId === undefined || data?.steamId === null) return;
      if (trustFactorRef.current?.value == null) return;
      setLoading(true);
      const trustFactor: number = Number(trustFactorRef.current?.value);
      const newData = await SetPlayerTrustFactor({
        steamId: data?.steamId,
        trustFactor,
      });
      setData((oldData) => {
        if (oldData !== undefined && newData.data !== undefined)
          oldData.trustFactor = newData.data.trustFactor;
        return oldData;
      });
      setLoading(false);
    };

    return (
      <Card>
        <div className={el`profile`}>
          <div className={el`actionButtons`}>
            <SimpleModal
              icon={<FaClipboard />}
              label={"Update Trust Factor"}
              className={el`actionButton`}
              onOkay={onUpdateTrustFactor}
              isLoading={loading}
            >
              <TextField
                inputRef={trustFactorRef}
                id={"trustFactor"}
                placeholder={"0"}
                size={"small"}
                label={"Trust Factor"}
              />
            </SimpleModal>
          </div>
        </div>
      </Card>
    );
  };

  const columns = useMemo<MRT_ColumnDef<CheatingReportResult>[]>(
    () => [
      {
        id: "id",
        header: "ID",
        accessorFn: (row) => row.reportid,
        size: 1,
      },
      {
        id: "reporter_SteamId",
        header: "Reporter SteamID",
        accessorFn: (row) => row.steamidreporter,
      },
      {
        id: "report",
        header: "Report",
        accessorFn: (row) => row.playerreport,
      },
      {
        id: "report_type",
        header: "Report Type",
        accessorFn: (row) => row.cheating_type,
      },
      {
        id: "time_ofReport",
        header: "Time of Report",
        accessorFn: (row) => row.timereport,
      },
    ],
    [],
  );

  const reportsCard = (): JSX.Element => {
    return (
      <MaterialReactTable
        columns={columns}
        data={reportsData ?? []}
        positionGlobalFilter="left"
        state={{ isLoading: loading, showGlobalFilter: true }}
        muiSearchTextFieldProps={{
          placeholder: "Search all reports",
          sx: { minWidth: "300px", padding: "8fpx" },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            header: "",
          },
        }}
        enableRowActions
        initialState={{
          density: "compact",
        }}
        enableDensityToggle={false}
        enableColumnFilters={false}
        enableHiding={false}
      />
    );
  };

  return (
    <div className={ROOT}>
      <Button
        variant={"outlined"}
        onClick={() => {
          setPage(Page.Players);
        }}
      >
        Back to Player list
      </Button>
      <div className={el`header-cards`}>
        {infoCard()}
        <div className={el`divider`}></div>
        {actionsCard()}
        <div className={el`divider`}></div>
        {reportsCard()}
        <div className={el`divider`}></div>
      </div>
    </div>
  );
}
