import { ReactElement, createContext, useEffect, useState } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import Page from "../../types/page";

const ROOT = makeRootClassName("Layout");
const el = makeElementClassNameFactory(ROOT);

type SPAProviderrProps = {
  children: ReactElement;
};

export const SPAContext = createContext({
  page: Page.Dashboard,
  setPage: (page: Page) => {},
  navigateBack: () => {},
  pageProps: { server: {}, player: {} },
  setPageProps: (props: any) => {},
});

export function SPAProvider(props: SPAProviderrProps): ReactElement {
  const [page, setPage] = useState<Page>(0);
  const [pageProps, setPageProps] = useState<any>();
  const [pageHistory, setPageHistory] = useState<Page[]>([]);

  const navigateBack = () => {
    const newPageHistory = [...pageHistory];
    newPageHistory.pop();
    setPageHistory(newPageHistory);

    const previousPage = newPageHistory[newPageHistory.length - 1];
    setPage(previousPage);
  };

  useEffect(() => {
    setPageHistory((prev) => [...prev, page]);

    const handleNavigation = () => {
      const currentState = window.history.state;
      const currentPage = (currentState?.page || 0) as Page;
      setPage(currentPage);
    };

    if (page !== window.history.state?.page) {
      window.history.pushState({ page: page }, "", "");
    }

    window.onpopstate = handleNavigation;

    return () => {
      setPageHistory([]);
    };
  }, [page]);

  return (
    <SPAContext.Provider
      value={{ page, setPage, navigateBack, pageProps, setPageProps }}
    >
      {props.children}
    </SPAContext.Provider>
  );
}
