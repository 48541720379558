import { ReactElement, useState } from "react";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../utils/classnames";
import {
  Avatar,
  Menu,
  Tooltip,
  IconButton,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { FaUser } from "react-icons/fa";
import { useAdmin } from "../../hooks/useAdmin";

const ROOT = makeRootClassName("UserAvatar");
const el = makeElementClassNameFactory(ROOT);

export function UserAvatar(): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { isAdmin } = useAdmin();

  return (
    <div className={ROOT}>
      <Tooltip title="Account">
        <IconButton onClick={handleClick}>
          <Avatar sx={{ width: 35, height: 35 }}></Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {isAdmin ? (
          <MenuItem
            onClick={() => {
              localStorage.removeItem("jwt");
              window.location.reload();
            }}
          >
            <ListItemIcon>
              <FaUser />
            </ListItemIcon>
            Logout
          </MenuItem>
        ) : (
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <FaUser />
            </ListItemIcon>
            Not Signed In
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
